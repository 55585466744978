// biome-ignore lint/style/useImportType: <explanation>
import * as React from 'react';

type MeetButtonProps = {
  href: string;
  children: React.ReactNode;
  pseudoClass: string;
};

const MeetButton: React.VoidFunctionComponent<MeetButtonProps> = ({
  href,
  children,
  pseudoClass,
}) => {
  return (
    <a
      href={href}
      className={`button my-5 inline-block whitespace-pre-line border border-solid border-current px-6 py-3 text-base font-medium ${pseudoClass}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default MeetButton;
