// biome-ignore lint/style/useImportType: <explanation>
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import type { PageContextProps } from '../../utils/graphql';
import * as Colors from '../Colors';
import DefaultLayout from '../layouts/default-layout';
import Definition from './Definition';
import Header from './Header';
import AllExpertises from './AllExpertises';
import Meet from './Meet';

type Theme = {
  headerClassName: string;
  headerPseudoClassName: string;
  circleClassName: string;
  definitionClassName: string;
  meetClassName: string;
  meetPseudoClass: string;
};

const themes: Record<string, Theme> = {
  product: {
    headerClassName: Colors.BLACK_ON_YELLOW,
    headerPseudoClassName: Colors.BLACK_ON_YELLOW_PSEUDO_CLASS,
    circleClassName: Colors.WHITE_ON_BLUE,
    definitionClassName: Colors.YELLOW_ON_BLACK,
    meetClassName: Colors.BLACK_ON_WHITE,
    meetPseudoClass: Colors.BLACK_ON_WHITE_PSEUDO_CLASS,
  },
  agility: {
    headerClassName: Colors.WHITE_ON_BLUE,
    headerPseudoClassName: Colors.WHITE_ON_BLUE_PSEUDO_CLASS,
    circleClassName: Colors.BLACK_ON_YELLOW,
    definitionClassName: Colors.BLACK_ON_WHITE,
    meetClassName: Colors.BLACK_ON_YELLOW,
    meetPseudoClass: Colors.BLACK_ON_YELLOW_PSEUDO_CLASS,
  },
  tech: {
    headerClassName: Colors.WHITE_ON_BLACK,
    headerPseudoClassName: Colors.WHITE_ON_BLACK_PSEUDO_CLASS,
    circleClassName: Colors.WHITE_ON_BLUE,
    definitionClassName: Colors.BLACK_ON_WHITE,
    meetClassName: Colors.WHITE_ON_BLUE,
    meetPseudoClass: Colors.WHITE_ON_BLUE_PSEUDO_CLASS,
  },
  academy: {
    headerClassName: Colors.WHITE_ON_LIGHTBLUE,
    headerPseudoClassName: Colors.WHITE_ON_LIGHTBLUE_PSEUDO_CLASS,
    circleClassName: Colors.WHITE_ON_BLACK,
    definitionClassName: Colors.BLACK_ON_WHITE,
    meetClassName: Colors.WHITE_ON_BLUE,
    meetPseudoClass: Colors.WHITE_ON_BLUE_PSEUDO_CLASS,
  },
};

type ExpertisePageProps = {
  offer: string;
  expertise: string;
  pageContext: PageContextProps;
};

const ExpertisePage: React.VoidFunctionComponent<ExpertisePageProps> = ({
  offer,
  expertise,
  pageContext,
}) => {
  const { t } = useTranslation('expertises');
  const theme = themes[offer];

  return (
    <DefaultLayout
      headTitle={t(`expertises.${offer}.${expertise}.head-title`)}
      headDescription={t(`expertises.${offer}.${expertise}.head-description`)}
      lang={pageContext.locale}
      theme={{ class: theme.headerClassName, pseudoClass: theme.headerPseudoClassName }}
      preserveNavbarSpace={false}
    >
      <article>
        <Header
          expertise={`${offer}.${expertise}`}
          className={theme.headerClassName}
          circleClassName={theme.circleClassName}
        />
        <Definition expertise={`${offer}.${expertise}`} className={theme.definitionClassName} />
        <Meet offer={offer} className={theme.meetClassName} pseudoClass={theme.meetPseudoClass} />
        <AllExpertises showTitle />
      </article>
    </DefaultLayout>
  );
};

export default ExpertisePage;
